import Footer from "../components/Footer";
import TopImage from "../images/Bridge2.gif"
import MiddleImage from "../images/Shop4.gif"
import BottomImage from "../images/Faqs2.gif"
// import MobileTopImage from "../images/Mobile-Bridge.gif"
// import MobileMiddleImage from "../images/Mobile-Shop.gif"
// import MobileBottomImage from "../images/Mobile-FAQs.gif"
import {Link} from "react-router-dom"

export default function Home() {
    return (<div className="cover">
    <Link to="/bridge" >
    <img className="cover-image" src={TopImage} alt="Bridge"/>
    </Link>
    <Link to="/market">
    <img className="cover-image" src={MiddleImage} alt="Shop"/>
    </Link>
    <Link to="/faq">
    <img className="cover-image" src={BottomImage} alt="FAQ"/>
    </Link>
    <Footer/></div>
    )
}