//import { Network, Alchemy } from 'alchemy-sdk';
require("dotenv").config();
const WS = process.env.REACT_APP_MAINNET_WS;
const WSL2 = process.env.REACT_APP_POLYGON_WS;
//const API_KEY = process.env.ALCHEMY_API_KEY;
const Web3 = require('web3')

// const web3 = new Web3(Web3.givenProvider || WS);
// const web3L2 = new Web3(Web3.givenProvider || WSL2);

const web3 = new Web3(WS);
const web3L2 = new Web3(WSL2);



// const auctionABI = require("../abi/auction-abi.json");
// * remove after test *
const auctionABI = require("../abi/auction-with-get-all-abi.json");
const inkzABI = require("../abi/inkz-abi.json");
const bridgeABI = require("../abi/bridge-abi.json");

//const whitelistABI = require("../abi/on-chain-whitelist.json");

// * remove after test*
const whitelistABI = require("../abi/on-chain-whitelist-with-get-all-abi.json");


export const inkzAddress = "0xd0c37Cda48Fe5D6Ee7132181a90DC58206Db5404";
export const inkzL2Address = "0x797886405F6CD36B5922227462c0A0E55d421570";
export const bridgeAddress = "0x797886405F6CD36B5922227462c0A0E55d421570";
// export const wlAddress = "0x3921D28435d9c3D129430879d97dFD044a5d3529";


// * remove after test*
export const wlAddress = "0x5f0bced4970c07ab292542ba18b915ccdfee33e6";

// * remove after test *
export const l2AuctionAddress = "0x17Bc2cd4dF2C5Cad25086af29B5F3bB3C3EeD170";
// export const l2AuctionAddress = "0xc95563d289EC3B292C20dC9C7669600CfB29a3f4";


// const settings = {
//     apiKey: API_KEY, // Replace with your Alchemy API Key.
//     network: Network.MATIC_MUMBAI, // Replace with your network.
//   };
// export const alchemy = new Alchemy(settings);

export const whitelistContract = new web3L2.eth.Contract(
    whitelistABI,
    wlAddress
)


export const auctionContract = new web3L2.eth.Contract(
    auctionABI,
    l2AuctionAddress
);

export const bridgeContract = new web3.eth.Contract(
    bridgeABI,
    bridgeAddress
)
  
export const inkzContract = new web3.eth.Contract(
    inkzABI,
    inkzAddress
);

export const inkzL2Contract = new web3L2.eth.Contract(
    inkzABI,
    inkzL2Address
);

export const inkzBalance = async (account) => {
    const bal = await inkzContract.methods.balanceOf(account).call();
    return bal;
}

export const inkzL2Balance = async (account) => {
    const bal = await inkzL2Contract.methods.balanceOf(account).call();
    return bal;
}

export const loadThisAuction = async (id) => {
    const auction = await auctionContract.methods.getAuctionInfo(id).call()
    return auction
}

// * remove after test *
export const getAllAuctions = async () => {

    try {
        const auctions = await auctionContract.methods.getAllAuctions().call()
        return auctions;
    } catch (err) {
        // console.log("auction contract not found")
        return {
            error: 1
        }
    }
}

// * remove after test *
export const getAllWhitelists = async () => {
    const wls = await whitelistContract.methods.getAllWhitelists().call()
    return wls;
}

export const connectWallet = async () => {
    if (window.ethereum) {
        try {
        const addressArray = await window.ethereum.request({
            method: "eth_requestAccounts",
        });
        let n = window.ethereum.chainId
        // mainnet chain id is 0x1, network id is 1
        // polygon chain id is 0x89, network id is 137
        // goerli chain is 0x5
        const obj = {
            status: "wallet connected",
            address: addressArray[0],
            chain: n,
        };
        // console.log(n)
        return obj;
        } catch (err) {
        return {
            address: "",
            status: "😥 " + err.message,
        };
        }
    } else {
        return {
        address: "",
        status: (
            <span>
            <p>
                {" "}
                🦊{" "}
                <a className="ref-style" target="_blank" rel="noreferrer" href={`https://metamask.io/download.html`}>
                You must install Metamask, a virtual Ethereum wallet, in your
                browser.
                </a>
            </p>
            </span>
        ),
        };
    }
};
  
export const getCurrentWalletConnected = async () => {
    if (window.ethereum) {
        try {
        const addressArray = await window.ethereum.request({
            method: "eth_accounts",
        });
        if (addressArray.length > 0) {
            return {
            address: addressArray[0],
            status: "wallet connected",
            };
        } else {
            return {
            address: "",
            status: "🦊 Connect to Metamask using the top right button.",
            };
        }
        } catch (err) {
        return {
            address: "",
            status: "😥 " + err.message,
        };
        }
    } else {
        return {
        address: "",
        status: (
            <span>
            <p>
                {" "}
                🦊{" "}
                <a  className="ref-style" target="_blank" rel="noreferrer" href={`https://metamask.io/download.html`}>
                You must install Metamask, a virtual Ethereum wallet, in your
                browser.
                </a>
            </p>
            </span>
        ),
        };
    }
};

export const makeBid = async (id, address) => {
    if (!window.ethereum || address === null) {
        return {
        status:
            "Connect your Metamask wallet to bid",
        }}
        // eslint-disable-next-line
    if (window.ethereum.networkVersion != 137) {
        // console.log(window.ethereum.networkVersion)
        return {
            status: null,
            error: "Switch to Polygon to bid!",
            }
    }
    const transactionParameters = {
        to: l2AuctionAddress,
        from: address,
        data: auctionContract.methods.bid(id).encodeABI(),
    }
    try {
        const txHash = await window.ethereum.request({
        method: "eth_sendTransaction",
        params: [transactionParameters],
        });
    return {
        status: (
            <span>
            <a  className="ref-style" target="_blank" rel="noreferrer" href={`https://mumbai.polygonscan.com/tx/${txHash}`}>
            ✅View on Etherscan!
            </a>
            <br />
            </span>
        ),
        error: null,
    };
        } catch (error) {
            return {
            error: "😥 " + error.message,
            };
    }
}

export const buyWhitelist = async (id, address) => {
    if (!window.ethereum || address === null) {
        return {
        status:
            "Connect your Metamask wallet to bid",
        }}
        // eslint-disable-next-line
    else if (window.ethereum.networkVersion != 137) {
        // console.log(window.ethereum.networkVersion)
        return {
            status: null,
            error: "Switch to Polygon to bid!",
            }
    }
    const transactionParameters = {
        to: wlAddress,
        from: address,
        data: whitelistContract.methods.buyWhitelist(id).encodeABI(),
    }
    try {
        const txHash = await window.ethereum.request({
        method: "eth_sendTransaction",
        params: [transactionParameters],
        });
    return {
        status: (
            <span>
            <a  className="ref-style" target="_blank" rel="noreferrer" href={`https://mumbai.polygonscan.com/tx/${txHash}`}>
            ✅View on Etherscan!
            </a>
            <br />
            </span>
        ),
        error: null,
    };
        } catch (error) {
            return {
            error: "😥 " + error.message,
            };
    }
}

export const claimWinner = async (id, address) => {
    if (!window.ethereum || address === null) {
        return {
        status:
            "Connect your wallet to claim",
        }}
    const transactionParameters = {
        to: l2AuctionAddress,
        from: address,
        data: auctionContract.methods.auctionEnd(id).encodeABI(),
    }
    try {
        const txHash = await window.ethereum.request({
        method: "eth_sendTransaction",
        params: [transactionParameters],
        });
        return {
            status: (
                <span>
                ✅{" "}
                <a  className="ref-style" target="_blank" rel="noreferrer" href={`https://mumbai.polygonscan.com/tx/${txHash}`}>
                        View on Etherscan!
                </a>
                <br />
                </span>
            ),
            error: null,
        };
            } catch (error) {
                return {
                error: "❌ " + error.message,
                };
        }
}

export const getContractOwner = async (wallet) =>  {
    const authorized = await whitelistContract.methods.authorized(wallet).call()
    return authorized
}

export const bridgeInkz = async (amount) => {
    if (!window.ethereum) {
        return {
        status:
            "Connect Wallet",
        }}
        // eslint-disable-next-line
    if (window.ethereum.networkVersion != 1) {
        // console.log(window.ethereum.networkVersion)
        return {
            btnstatus: "Bridge",
            status: null,
            error: "Switch to mainnet to bridge",
            }
    }

    const currentlyConnected = await getCurrentWalletConnected()
    const transactionParameters = {
        to: bridgeAddress,
        from: await currentlyConnected.address,
        data: bridgeContract.methods.deposit(currentlyConnected.address, amount).encodeABI(),
    }
    try {
        const txHash = await window.ethereum.request({
        method: "eth_sendTransaction",
        params: [transactionParameters],
        });
        return {
            status: (
                <span>
                <a  className="ref-style" target="_blank" rel="noreferrer" href={`https://goerli.etherscan.io/tx/${txHash}`}>
                ✅ View on Etherscan!
                </a>
                <br />
                </span>
            ),
            error: null,
            btnstatus: "Bridge",
        };
            } catch (error) {
                return {
                error: "❌ " + error.message,
                btnstatus: "Bridge",
                status: ""
                };
        }
}

export const makeAuction = async (auctionName, auctionImage, startTime, endTime, bidIncrement, bidTimeExtension, startingPrice) => {
    if (!window.ethereum) {
        return {
        status:
            "Connect your wallet to post auction",
        }}
    const connected = await getCurrentWalletConnected()
    const owner = connected.address
    const transactionParameters = {
        to: l2AuctionAddress,
        from: owner,
        data: auctionContract.methods.createAuction(auctionName, auctionImage, startTime, endTime, bidIncrement, bidTimeExtension, startingPrice).encodeABI(),
    }
    try {
        const txHash = await window.ethereum.request({
        method: "eth_sendTransaction",
        params: [transactionParameters],
        });
        return {
            status: (
                <span>
                ✅{" "}
                <a  className="ref-style" target="_blank" rel="noreferrer" href={`https://mumbai.polygonscan.com/tx/${txHash}`}>
                        View on Etherscan!
                </a>
                <br />
                </span>
            ),
            error: null,
        };
            } catch (error) {
                return {
                error: "❌ " + error.message,
                };
        }
}

export const makeWhitelist = async (whitelistName, whitelistImage, startTime, endTime, whitelistSlots, whitelistPrice, isBurn) => {
    if (!window.ethereum) {
        return {
        status:
            "Connect your wallet to post whitelist",
        }}
        const connected = await getCurrentWalletConnected()
        const owner = connected.address
        const transactionParameters = {
            to: wlAddress,
            from: owner,
            data: whitelistContract.methods.startWhitelist(whitelistName, whitelistImage, startTime, endTime, whitelistSlots, whitelistPrice, isBurn).encodeABI(),
        }

        try {
            const txHash = await window.ethereum.request({
            method: "eth_sendTransaction",
            params: [transactionParameters],
            });
            return {
                status: (
                    <span>
                    ✅{" "}
                    <a  className="ref-style" target="_blank" rel="noreferrer" href={`https://mumbai.polygonscan.com/tx/${txHash}`}>
                            View on Etherscan!
                    </a>
                    <br />
                    </span>
                ),
                error: null,
            };
                } catch (error) {
                    return {
                    error: "❌ " + error.message,
                    };
            }
}