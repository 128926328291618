import { useState } from "react";
import { makeAuction, makeWhitelist } from "./Interact";
import "../OwnerPanel.css";

const LoadAuctionPanel = () => {
  const [auctionName, setAuctionName] = useState("");
  const [auctionImage, setAuctionImage] = useState("");
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");
  const [bidIncrement, setBidIncrement] = useState("");
  const [bidTimeExtension, setBidTimeExtension] = useState("");
  const [startingPrice, setStartingPrice] = useState("");
  const [isBurn, setIsBurn] = useState("");

  const [whitelistName, setWhitelistName] = useState("");
  const [whitelistImage, setWhitellistImage] = useState("");
  const [whitelistSlots, setWhitelistSlots] = useState("");
  const [whitelistStartTime, setWhitelistStartTime] = useState("");
  const [whitelistEndTime, setWhitelistEndTime] = useState("");
  const [whitelistPrice, setWhitelistPrice] = useState("");

  const handleSubmit = (event) => {
    const start = +new Date(startTime);
    const end = +new Date(endTime);
    event.preventDefault();
    console.log(bidTimeExtension * 60);
    console.log(bidIncrement * 1000000000000000000);
    console.log(start / 1000);
    console.log(end / 1000);
    makeAuction(
      auctionName,
      auctionImage,
      start / 1000,
      end / 1000,
      (bidIncrement * 1000000000000000000).toString(),
      (bidTimeExtension * 60).toString(),
      (startingPrice * 1000000000000000000).toString()
    );
  };
  const handleWhitelistSetup = (event) => {
    const start = +new Date(whitelistStartTime);
    const end = +new Date(whitelistEndTime);
    event.preventDefault();
    makeWhitelist(
      whitelistName,
      whitelistImage,
      start / 1000,
      end / 1000,
      whitelistSlots,
      (whitelistPrice * 1000000000000000000).toString(),
      isBurn
    );
  };

  return (
    <>
    <div className='welcome-bar'>
        <div className='text-l'>
          Admin Panel
        </div>
      </div>
    <div className="form-container flex">
      
      <div className="form-box">
        <h1>Setup Auction</h1>
        <form onSubmit={handleSubmit}>
          <label className="format-form">
            Item name:
            <input
              className="input-textfield"
              type="text"
              placeholder="OctoHedz #888..."
              required
              value={auctionName}
              onChange={(e) => setAuctionName(e.target.value)}
            />
          </label>
          <label className="format-form">
            Image URL:
            <input
              className="input-textfield"
              type="text"
              placeholder="https://imag..."
              required
              value={auctionImage}
              onChange={(e) => setAuctionImage(e.target.value)}
            />
          </label>
          <label className="format-form">
            Starting price:
            <input
              className="input-textfield"
              type="number"
              min="1"
              value={startingPrice}
              required
              onChange={(e) => setStartingPrice(e.target.value)}
            />
          </label>
          <label className="format-form">
            Start time:
            <input
              className="input-textfield"
              type="datetime-local"
              value={startTime}
              required
              onChange={(e) => setStartTime(e.target.value)}
            />
          </label>
          <label className="format-form">
            End time:
            <input
              className="input-textfield"
              type="datetime-local"
              value={endTime}
              required
              onChange={(e) => setEndTime(e.target.value)}
            />
          </label>
          <label className="format-form">
            Enter bid increment:
            <input
              className="input-textfield"
              type="number"
              min="1"
              value={bidIncrement}
              required
              onChange={(e) => setBidIncrement(e.target.value)}
            />
          </label>
          <label className="format-form">
            Bid time threshold in minutes:
            <input
              className="input-textfield"
              type="number"
              min="1"
              value={bidTimeExtension}
              required
              onChange={(e) => setBidTimeExtension(e.target.value)}
            />
          </label>
          <input className="input-btn" type="submit" />
        </form>
      </div>


      <div className="form-box-2">
        <h1>Setup Market</h1>
        <form onSubmit={handleWhitelistSetup}>
          <label className="format-form">
            Item name:
            <input
              className="input-textfield"
              type="text"
              placeholder="OctoHedz"
              required
              value={whitelistName}
              onChange={(e) => setWhitelistName(e.target.value)}
            />
          </label>
          <label className="format-form">
            Image URL:
            <input
              className="input-textfield"
              type="text"
              placeholder="https://imag..."
              required
              value={whitelistImage}
              onChange={(e) => setWhitellistImage(e.target.value)}
            />
          </label>
          <label className="format-form">
            Start time:
            <input
              className="input-textfield"
              type="datetime-local"
              value={whitelistStartTime}
              required
              onChange={(e) => setWhitelistStartTime(e.target.value)}
            />
          </label>
          <label className="format-form">
            End time:
            <input
              className="input-textfield"
              type="datetime-local"
              value={whitelistEndTime}
              required
              onChange={(e) => setWhitelistEndTime(e.target.value)}
            />
          </label>
          <label className="format-form">
            Number of spots available:
            <input
              className="input-textfield"
              type="number"
              min="1"
              value={whitelistSlots}
              required
              onChange={(e) => setWhitelistSlots(e.target.value)}
            />
          </label>
          <label className="format-form">
            Purchase price:
            <input
              className="input-textfield"
              type="number"
              min="1"
              value={whitelistPrice}
              required
              onChange={(e) => setWhitelistPrice(e.target.value)}
            />
          </label>
          <label className="format-form">
            Burn tokens?:
            <input
              className="input-textfield"
              type="text"
              placeholder="true/false (no caps)"
              maxLength="5"
              value={isBurn}
              required
              onChange={(e) => setIsBurn(e.target.value)}
            />
          </label>
          <input className="input-btn" type="submit" />
        </form>
      </div>
    </div>
    </>
  );
};
export default LoadAuctionPanel;
