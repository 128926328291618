import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import truncateEthAddress from "truncate-eth-address";
import Web3 from "web3";
import OctoLogo from "../images/Octo-logo.png";
import InkzLogoL2 from "../images/inkz-L2.gif";
import InkzLogo from "../images/inkz.gif";
import {
  connectWallet,
  inkzL2Balance,
  inkzBalance,
  bridgeContract,
  inkzL2Contract,
} from "./Interact";
import './Navbar.css';
//import Spinner from "./Spinner";

export default function Navbar() {
  const [click, setClick] = useState(false);
  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);


  const [buttonText, setButtonText] = useState("connect");
  const [walletAddress, setWallet] = useState("");
  const [status, setStatus] = useState("");
  const [networkID, setNetworkID] = useState("");
  const [inkzL2Bal, setInkzL2Bal] = useState(null);
  const [inkzBal, setInkzBal] = useState(null);
  //const [bridgeStatus, setBridgeStatus] = useState("")
  //const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    addWalletListener();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    function updateWalletBalances() {
      if (walletAddress) {
        bridgeContract.events
          .depositInkz({})
          .on("connected", function (subscriptionId) {
            // console.log("Inkz L1 listener sub ID: " + subscriptionId);
          })
          .on("data", function (event) {
            if (event.returnValues[0] === walletAddress) {
              checkL1InkzBalance(event.returnValues[0]);
            } else {
              //console.log("not your deposit")
            }
          });
      } else {
        // console.log("wallet address not set!");
      }
    }
    updateWalletBalances();
  }, [walletAddress]);

  useEffect(() => {
    function updateInkzL2Balance() {
      if (walletAddress) {
        inkzL2Contract.events
          .Transfer({})
          .on("connected", function (subscriptionId) {
            // console.log("Inkz L2 listener sub ID: " + subscriptionId);
          })
          .on("data", function (event) {
            //console.log(event)
            if (event.returnValues[1] === walletAddress) {
              checkL2InkzBalance(event.returnValues[1]);
            } else if (event.returnValues[0] === walletAddress) {
              checkL2InkzBalance(event.returnValues[0]);
            }
          });
      } else {
        // console.log("wallet address not set");
      }
    }
    updateInkzL2Balance();
  }, [walletAddress]);

  const checkL1InkzBalance = async (event) => {
    const inkz = await inkzBalance(event);
    setInkzBal(Math.trunc(Web3.utils.fromWei(inkz, "ether")));
  };

  const checkL2InkzBalance = async (event) => {
    const inkzL2 = await inkzL2Balance(event);
    setInkzL2Bal(Math.trunc(Web3.utils.fromWei(inkzL2, "ether")));
  };

  const connectWalletPressed = async () => {
    const walletResponse = await connectWallet();
    setWallet(Web3.utils.toChecksumAddress(walletResponse.address));
    setNetworkID(walletResponse.chain);
    const inkzL2 = await inkzL2Balance(walletResponse.address);
    setInkzL2Bal(Math.trunc(Web3.utils.fromWei(inkzL2, "ether")));
    const inkz = await inkzBalance(walletResponse.address);
    setInkzBal(Math.trunc(Web3.utils.fromWei(inkz, "ether")));
    if (walletAddress !== " ") {
      setButtonText(truncateEthAddress(walletResponse.address));
    }
  };

  function addWalletListener() {
    if (window.ethereum) {
      window.ethereum.on("accountsChanged", (accounts) => {
        if (accounts.length > 0) {
          setWallet(accounts[0]);
          connectWalletPressed();
        } else {
          setWallet("");
          setStatus("🦊 Connect to Metamask using the top right button.");
        }
      });
    } else {
      setButtonText(
        "No Wallet Detected!"
      );
    }
  }

  return (
    <>
      <nav className="navbar">
          <Link
            to="/"
            state={{ from: { walletAddress }, network: { networkID } }}
          >
            <img className="navbar-logo" src={OctoLogo} alt="OctoHedz Market" />
          </Link>
          <button className="text connect-btn" onClick={connectWalletPressed}>
            {buttonText}
          </button>
          {walletAddress ? (
            <div className="flex-container-inkz">
              <div className="text inkz-border ml-4">
                <img src={InkzLogoL2} alt="inkz" className="inkz-image" />
                <a href="https://mumbai.polygonscan.com/token/0x962F803Dc1bA8d645a217D0Bd16d4D8a63971adB" target="_blank" rel="noreferrer" className="text">{inkzL2Bal}</a>
              </div>
              <div className="text inkz-border ml-4">
                <img src={InkzLogo} alt="inkz" className="inkz-image" />
                <a href="https://goerli.etherscan.io/token/0xbab58D524E289F4E867A24D583b25313F4189a03" target="_blank" rel="noreferrer" className="text">{inkzBal}</a>
              </div>
            </div>
          ) : null}
          
        <div className="menu-icon" onClick={handleClick}>
          <i className={click ? "fas fa-times" : "fas fa-bars"} />
        </div>
        <ul className={click ? "nav-menu active" : "nav-menu"}>
        <li className="nav-item">
            <Link
              to="/"
              onClick={closeMobileMenu}
              className="nav-links"
            >
              Home
            </Link>
          </li>
          <li className="nav-item">
            <Link
              to="/market"
              onClick={closeMobileMenu}
              className="nav-links"
            >
              Market
            </Link>
          </li>
          <li className="nav-item">
            <Link
              to="/bridge"
              onClick={closeMobileMenu}
              className="nav-links"
            >
              Bridge
            </Link>
          </li>
        </ul>
        <div>    
        </div>
        {status}
      </nav>
    </>
  );
}
